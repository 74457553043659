import { FormikConfig } from 'formik';
import { object, string } from 'yup';

export interface FormSignup {
  email: string;
  password: string;
}

export const initialValues: FormSignup = {
  email: '',
  password: '',
};

export const schema = object({
  email: string()
    .required('A valid email address is required.')
    .email('A valid email address is required.'),
  password: string()
    .required('Password is required.')
    .matches(/[0-9]/, 'Password must contain a number.')
    .matches(/[a-z]/, 'Password must contain an lowercase letter.')
    .matches(/[A-Z]/, 'Password must contain an uppercase letter.')
    .max(20, 'Password must be less than 20 characters.')
    .min(8, 'Password must be at least 8 characters.'),
});

export const form: FormikConfig<FormSignup> = {
  initialValues,

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onSubmit: () => {}, // We just need a stub, we use the standard form submit

  validateOnBlur: false,
  validateOnChange: true,
  validateOnMount: true,
  validationSchema: schema,
};
